<template>
    <div class="text-overflow">{{ createdOn }}</div>
    <div class="text-overflow" style="display: flex;">
        <div class="status" :class="[item.status]">{{ item.status }}</div>
    </div>
    <div class="text-overflow">{{ item.loads.length }} {{ item.loads.length === 1 ? 'load' : 'loads' }}</div>
    <div class="text-overflow total"><span class="strong">${{ CONVERT_PRICE(Number(item.total).toFixed(2)) }}</span>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: ['item'],
    emits: ['statusChange'],
    data() {
        return {
            statusList: [
                { value: 'pending', name: 'Awaiting payment' },
                { value: 'paid', name: 'Paid' },
            ],
            lastStatus: this.item.status,
            showStatus: this.item.showStatus
        }
    },
    methods: {
        async statusChange(status) {
            if (status.value === 'canceled') {
                let confirmCancel = await this.$ShowConfirm(`Do you really wish to cancel this invoice? You won't be able to undo this action.`);
                if (!confirmCancel) {
                    this.item.status = this.lastStatus;
                    return;
                }
            }

            this.lastStatus = status.value;
            this.$emit('statusChange', status.value);
        }
    },
    computed: {
        createdOn() {
            return moment.utc(this.item.createdOn).format("ddd D MMM");
        }
    }
}
</script>

<style lang="scss" scoped>
.profile {
    display: grid;
    align-items: center;
    grid-template-columns: 34px minmax(0, auto);
    column-gap: 15px;

    .image {
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 9px;
    }
}

.total {
    color: $success;
}

.strong {
    font-weight: 600;
}

.status-holder {
    display: flex;
}

.status {
    height: 28px;
    border-radius: 7px;
    transition: ease 0.3s;
    border: 1px solid $themeColor2;
    color: $themeColor2;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0 10px 0 20px;
    position: relative;
    font-size: 11px;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 7px;
        width: 6px;
        height: 6px;
        background: $themeColor2;
        border-radius: 50%;
        transform: translate(0, -50%);
    }

    &.paid {
        border: 1px solid $success;
        color: $success;

        &::before {
            background: $success;
        }
    }
    
}

.invoice-status {
    height: 28px;
    background: $themeColor1;
    padding: 0 10px;
    border-radius: 14px;
    transition: ease 0.3s;
    border: 1px solid $borderColor;

    &:after {
        content: none !important;
    }

    &.pending {
        border: 1px solid $borderColor;
        background: $themeColor1;
        color: $textShade;
    }

    &.paid {
        border: 1px solid $success;
        background: $success;
        color: #fff;
    }

    &.canceled {
        border: 1px solid $error;
        background: $error;
        color: #fff;
    }
}
</style>