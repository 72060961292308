<template>
    <div class="grid gap-20">
        <ActionBar title="My Payments"></ActionBar>


        <div class="item-list">
            <TableView :head="tableHead" :size="columnsSizes" v-if="(_pageManager.list.length > 0)"
                :loading="isLoading(['GetListOfPayments'])">
                <TableRow :size="columnsSizes" :key="item._id" v-for="(item, key) of _pageManager.list">
                    <PaymentItem :item="item" />
                </TableRow>
            </TableView>


            <div v-else>
                <div v-if="isLoading(['GetListOfPayments'])" class="spinner-flex">
                    <Spinner />
                </div>
                <div v-else>
                    <NoDataMessage text="Nothing found" />
                </div>
            </div>
        </div>


        <div class="pagination">
            <Pagination :manager="_pageManager" @pageChange="(page) => { _pageManager.query.page = page }" />
        </div>

    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useReferralsStore } from '../../store/Referrals';

import PaginationJS from '../../mixins/Pagination'
import Pagination from '../../mixins/Pagination/components/Pagination.vue'

import PaymentItem from './components/PaymentItem.vue'

export default {
    mixins: [PaginationJS],
    components: {
        Pagination,
        PaymentItem
    },
    data() {
        return {
            tableHead: [
                { name: 'Date' },
                { name: 'Status' },
                { name: 'No. of loads' },
                { name: 'Amount Paid' },
            ],
            columnsSizes: [
                'minmax(50px, 1fr)',
                'minmax(50px, 1fr)',
                'minmax(50px, 1fr)',
                'minmax(50px, 1fr)',
            ],
        }
    },
    watch: {
        "_pageManager.query.search": {
            deep: true,
            handler() {
                this._pageManager.query.page = 1;
            }
        },
        "_pageManager.query": {
            deep: true,
            handler() {
                this.getUsers();
            }
        }
    },
    computed: {
        ...mapStores(useReferralsStore)
    },
    methods: {
        getPayments() {
            this.ajax("GetListOfPayments", {
                url: '/referrals/user/payments',
                method: 'GET'
            }, (err, body) => {
                if (!err) {
                    this._pageManager.pages = body.pages;
                    this._pageManager.count = body.count;
                    this._pageManager.list = body.list;
                    this._pageManager.page = body.page;
                    this._pageManager.hasNextPage = body.hasNextPage;
                    this._pageManager.hasPrevPage = body.hasPrevPage;
                }
            });
        }
    },
    mounted() {
        this.getPayments();
    }
}
</script>

<style lang="scss" scoped>
.item-list {
    border-bottom: 1px solid $borderColor;
    padding: 0 0 20px 0;
}
</style>